/**
 * Created by neo on 29.02.2024
 */
import { computed, observable, onBecomeObserved, runInAction, toJS } from 'mobx';
import { Pageable } from '../Pageable';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { LocalizedArrayEntity, LocalizedArrayEntityJson } from '../LocalizedArrayEntity';
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';
import { Gym } from '../Gym/Gym';
import { Media, MediaJson } from '../Media/Media';

export type CampaignCalendarQueryRequest = Pageable & {
  query?: string;
  startDateTime?: Date;
  endDateTime?: Date;
  timezone?: string;
};

export type CampaignCalendarTemplateItemJson = LocalizedArrayEntityJson & {
  type: string;
  parentIds: string[];
  gymId?: string;
  publishDate?: string;
  unpublishDate?: string;
  tags: string[];
  images: MediaJson[];
};

export class CampaignCalendarTemplateItem extends LocalizedArrayEntity {
  @observable
  type = 'entry';
  @observable
  parentIds: string[] = [];
  @observable
  gymId?: string;
  @observable
  publishDate?: Date;
  @observable
  unpublishDate?: Date;
  @observable
  tags: string[] = [];
  @observable
  images: Media[] = [];
  @observable
  gym?: Gym;

  constructor(json?: Partial<CampaignCalendarTemplateItemJson>) {
    super(json);
    if (json) {
      this.type = json.type ?? 'entry';
      this.parentIds = json.parentIds ?? [];
      this.gymId = json.gymId;
      this.publishDate = json.publishDate ? new Date(json.publishDate) : undefined;
      this.unpublishDate = json.unpublishDate ? new Date(json.unpublishDate) : undefined;
      this.tags = json.tags ?? [];
      this.images = json.images?.map((i) => new Media(i)) ?? [];
    }

    onBecomeObserved(this, 'gym', () => this.fetchGym());
  }

  fetchGym() {
    if (!this.gym && this.gymId) {
      Gym.get(this.gymId).then((gym) => runInAction(() => (this.gym = gym)));
    }
  }

  toJS(newId?: boolean): CampaignCalendarTemplateItemJson {
    return {
      ...super.toJS(newId),
      type: this.type,
      gymId: this.gymId,
      parentIds: toJS(this.parentIds),
      publishDate: this.publishDate?.toISOString(),
      unpublishDate: this.unpublishDate?.toISOString(),
      tags: this.tags,
      images: this.images.map((i) => i.toJS()),
    };
  }

  copy(): CampaignCalendarTemplateItem {
    const copy = new CampaignCalendarTemplateItem(this.toJS(true));
    copy.name = copy.name.map(({ lang, value }) => new LocalizedValue({ lang, value: `${value} (Copy)` }));
    copy.publishDate = undefined;
    copy.unpublishDate = undefined;
    return copy;
  }

  save(): Promise<CampaignCalendarTemplateItem> {
    return HttpBackend.post(`/gym/campaign/calendar/template/admin`, this.toJS()).then((res) => this);
  }

  delete(): Promise<CampaignCalendarTemplateItem> {
    return HttpBackend.delete(`/gym/campaign/calendar/template/admin/${this.id}`).then((res) => this);
  }

  @computed
  get published(): boolean {
    return (
      !!this.publishDate &&
      this.publishDate.valueOf() < Date.now() &&
      (!this.unpublishDate || this.unpublishDate.valueOf() > Date.now())
    );
  }

  static findOne(campaignId: string): Promise<CampaignCalendarTemplateItem> {
    return HttpBackend.get(`/gym/campaign/calendar/template/admin/${campaignId}`).then(
      (res) => new CampaignCalendarTemplateItem(res),
    );
  }

  static find(request?: Partial<CampaignCalendarQueryRequest>): Promise<CampaignCalendarTemplateItem[]> {
    return HttpBackend.get(`/gym/campaign/calendar/template/admin`, request).then((res) =>
      res.map((c) => new CampaignCalendarTemplateItem(c)),
    );
  }

  static count(request?: Partial<CampaignCalendarQueryRequest>): Promise<number> {
    return HttpBackend.get(`/gym/campaign/calendar/admin/template/count`, request).then((res) => res ?? 0);
  }
}
