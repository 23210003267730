import { HttpBackend } from '../../Services/Http/HttpBackend';
import { toJS } from 'mobx';
import { CampaignCalendarQueryRequest, CampaignCalendarTemplateItem } from './CampaignCalendarTemplateItem';
import { CampaignCalendarTemplateFolder } from './CampaignCalendarTemplateFolder';
import { CampaignCalendarTemplateEntry } from './CampaignCalendarTemplateEntry';

export class CampaignCalendarTemplateItemBuilder {
  static find(request: CampaignCalendarQueryRequest): Promise<CampaignCalendarTemplateItem[]> {
    return HttpBackend.get(`/gym/campaign/calendar/template/admin`, toJS(request)).then((res) =>
      (res ?? []).map((r) => {
        switch (r.type) {
          case 'folder':
            return new CampaignCalendarTemplateFolder(r);
          case 'entry':
          default:
            return new CampaignCalendarTemplateEntry(r);
        }
      }),
    );
  }

  static count(request?: Partial<CampaignCalendarQueryRequest>): Promise<number> {
    return HttpBackend.get(`/gym/campaign/calendar/template/admin/count`, toJS(request));
  }

  static findOne(id: string): Promise<CampaignCalendarTemplateItem> {
    return HttpBackend.get(`/gym/campaign/calendar/template/admin/${id}`).then((res) => {
      switch (res.type) {
        case 'folder':
          return new CampaignCalendarTemplateFolder(res);
        case 'entry':
        default:
          return new CampaignCalendarTemplateEntry(res);
      }
    });
  }
}
