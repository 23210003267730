/**
 * Created by neo on 31.01.2025
 */
import { observable } from 'mobx';

export type MediaVideoAssetsJson = {
  hls?: string;
  iframe?: string;
  player?: string;
  thumbnail?: string;
  mp4?: string;
};

export class MediaVideoAssets {

  @observable
  hls?: string;
  @observable
  iframe?: string;
  @observable
  player?: string;
  @observable
  thumbnail?: string;
  @observable
  mp4?: string

  constructor(json?: Partial<MediaVideoAssetsJson>) {
    if (json) {
      this.hls = json.hls;
      this.iframe = json.iframe;
      this.player = json.player;
      this.thumbnail = json.thumbnail;
      this.mp4 = json.mp4;
    }
  }

  toJS(): MediaVideoAssetsJson {
    return {
      hls: this.hls,
      iframe: this.iframe,
      player: this.player,
      thumbnail: this.thumbnail,
      mp4: this.mp4
    };
  }
}
