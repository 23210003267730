/**
 * Created by neo on 11.03.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { CampaignCalendarTemplateItem } from '../../../../Model/CampaignCalendar/CampaignCalendarTemplateItem';
import { CampaignCalendarTemplateItemBuilder } from '../../../../Model/CampaignCalendar/CampaignCalendarTemplateItemBuilder';
import { CampaignCalendarTemplateEntry } from '../../../../Model/CampaignCalendar/CampaignCalendarTemplateEntry';
import { CampaignCalendarTemplateFolder } from '../../../../Model/CampaignCalendar/CampaignCalendarTemplateFolder';
import { CampaignCalendarTemplateFolderEditModal } from './CampaignCalendarTemplateFolderEditModal';
import { CampaignCalendarEntryModal } from '../CampaignCalendarModal/CampaignCalendarEntryModal';

export type CampaignCalendarItemEditModalProps = {
  onSaved?: (category: CampaignCalendarTemplateItem) => void;
  onDeleted?: (category: CampaignCalendarTemplateItem) => void;
};

export const CampaignCalendarItemEditModal: React.FC<CampaignCalendarItemEditModalProps> = observer(
  ({ onSaved, onDeleted }) => {
    const location = useLocation();
    const parentId = new URLSearchParams(location.search).get('parentId') ?? undefined;
    const gymId = new URLSearchParams(location.search).get('gymId') ?? undefined;
    const type = new URLSearchParams(location.search).get('type') ?? undefined;

    const { campaignId } = useParams<{ campaignId: string }>();
    const [content, setContent] = useState<CampaignCalendarTemplateItem | undefined>();
    const isNew = campaignId === 'create';

    useEffect(() => {
      if (campaignId && campaignId !== 'create') {
        CampaignCalendarTemplateItemBuilder.findOne(campaignId).then((res) => setContent(res));
      } else if (type === 'entry') {
        setContent(new CampaignCalendarTemplateEntry({ parentIds: parentId ? [parentId] : [], gymId }));
      } else {
        setContent(new CampaignCalendarTemplateFolder({ parentIds: parentId ? [parentId] : [], gymId }));
      }
    }, [parentId, campaignId, type, gymId]);

    const handleEntryChanged = React.useCallback((newEntry: CampaignCalendarTemplateItem) => setContent(newEntry), []);

    if (content instanceof CampaignCalendarTemplateFolder) {
      return <CampaignCalendarTemplateFolderEditModal category={content} isNew={isNew} onSaved={onSaved} />;
    } else if (content instanceof CampaignCalendarTemplateEntry) {
      return (
        <CampaignCalendarEntryModal
          campaign={content}
          isNew={isNew}
          onSave={onSaved}
          onDeleted={onDeleted}
          // onEntryChanged={handleEntryChanged}
        />
      );
    }

    return null;
  },
);
