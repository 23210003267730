/**
 * Created by neo on 20.06.23
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Checkbox, Form, Input, Modal } from 'antd';
import { Customer } from '../../../../../Model/Customer/Customer';
import { Col, Row } from 'reactstrap';

export type CustomerEditModalProps = {};

export const CustomerEditModal: React.FC<CustomerEditModalProps> = observer(() => {
  const { customerId } = useParams();
  const navigate = useNavigate();

  const [saving, setSaving] = useState(false);
  const [customer, setCustomer] = useState<Customer | undefined>();

  useEffect(() => {
    if (customerId) {
      Customer.get(customerId).then((res) => res && setCustomer(res));
    }
  }, [customerId]);

  const handleSave = () => {
    if (customer) {
      setSaving(true);
      customer.save().finally(() => setSaving(false));
    }
  };

  const handleClose = () => {
    navigate(-1);
  };

  if (customer) {
    return (
      <Modal
        title={customer.athlete?.fullName || customer.athlete?.user.email}
        open={true}
        onOk={handleSave}
        onCancel={handleClose}
        confirmLoading={saving}
        width={1024}
      >
        <Form layout="vertical">
          <Row>
            <Col md={12}>
              <Form.Item label="Collected Points">
                <Input value={customer.pointsLedger?.totalCollectedPoints?.toLocaleString()} disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }

  return null;
});
