/**
 * Created by neo on 12.01.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Alert, Button, Form, message, Modal, Tabs } from 'antd';
import { runInAction } from 'mobx';
import { WorkoutTemplateEntry } from '../../../../Model/Explore/WorkoutTemplateEntry';
import { CoachWorkout } from '../../../../Model/Coach/Program/Workout/CoachWorkout';
import { ExploreEntryContentSwitch } from '../Content/ExploreEntryContentSwitch';
import { CoachWorkoutView } from '../../AthleteSuperMacro/View/CoachWorkoutView/CoachWorkoutView';
import { MediaEditModal } from '../../../MetaData/Media/MediaEditModal/MediaEditModal';
import { Media } from '../../../../Model/Media/Media';
import { ExploreEntryModalVideoImageFields } from './ExploreEntryModalVideoImageFields';
import { ExploreEntry } from '../../../../Model/Explore/ExploreEntry';
import { TabBasicInfo, TabBasicInfoProps } from './TabBasicInfo';
import { TabNotification } from './TabNotification';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { ExploreEntryModalAnalytics } from './ExploreEntryModalAnalytics/ExploreEntryModalAnalytics';
import dayjs from '../../../../Utils/dayjs';
import { HttpBackend } from '../../../../Services/Http/HttpBackend';
import saveAs from 'file-saver';
import { FaqTabContent } from '../Content/FaqTab/FaqTabContent';
import { generateExploreEntryTags } from './generateExploreEntryTags';
import { translateExploreFilterTags } from './translateExploreFilterTags';

export type ExploreEntryModalProps = Pick<TabBasicInfoProps, 'onEntryChanged'> & {
  entry: ExploreEntry;
  isNew?: boolean;
  onSaved?: (entry: ExploreEntry) => void;
  onDeleted?: (entry: ExploreEntry) => void;
};

export const ExploreEntryModal: React.FC<ExploreEntryModalProps> = observer(
  ({ entry, isNew, onSaved, onDeleted, onEntryChanged }) => {
    const history = useNavigate();

    const [generatedWorkout, setGeneratedWorkout] = useState<CoachWorkout | undefined>();
    const [processing, setProcessing] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState<Media | undefined>();

    const [activeTab, setActiveTab] = useState('basic');

    const isDefault = entry.type === 'default';
    const canHaveNotifications = entry.type !== 'link' && !isDefault;

    const handleChangeTab = React.useCallback((tab: string) => setActiveTab(tab), []);

    const handleUnpublish = React.useCallback(() => {
      runInAction(
        () =>
          (entry.unpublishDate =
            entry.unpublishDate && dayjs(entry.unpublishDate).isBefore(dayjs()) ? entry.unpublishDate : new Date()),
      );

      setProcessing(true);
      onSaved && onSaved(entry);
      entry
        .save()
        .then(() => {
          message.success('Entry unpublished!');
        })
        .catch(() => {
          message.error('Error unpublishing entry!');
        })
        .finally(() => setProcessing(false));
    }, [entry, onSaved]);

    const handlePublish = React.useCallback(() => {
      runInAction(() => {
        entry.publishDate =
          entry.publishDate && dayjs(entry.publishDate).isBefore(dayjs()) ? entry.publishDate : new Date();
        entry.unpublishDate =
          !entry.unpublishDate || dayjs(entry.unpublishDate).isAfter(dayjs()) ? entry.unpublishDate : undefined;
      });

      setProcessing(true);
      Promise.all([generateExploreEntryTags(entry), translateExploreFilterTags(entry)])
        .then(() => entry.save())
        .finally(() => setProcessing(false))
        .then(() => {
          message.success('Entry published!');
          onSaved && onSaved(entry);
        })
        .catch(() => message.error('Error publishing entry!'));
    }, [entry, onSaved]);

    const handleSave = React.useCallback(() => {
      setProcessing(true);
      Promise.all([generateExploreEntryTags(entry), translateExploreFilterTags(entry)])
        .then(() => entry.save())
        .finally(() => setProcessing(false))
        .then(() => {
          message.success('Entry saved!');
          onSaved && onSaved(entry);
        })
        .catch(() => message.error('Error saving entry!'));
    }, [entry, onSaved]);

    const handleDelete = React.useCallback(() => {
      setProcessing(true);
      onDeleted && onDeleted(entry);
      entry
        .delete()
        .then(() => {
          message.success('Entry deleted!');
        })
        .finally(() => setProcessing(false))
        .then(() => history(-1))
        .catch(() => message.error('Error deleting entry!'));
    }, [entry, history, onDeleted]);

    const handleCancel = React.useCallback(() => history(-1), [history]);

    const handleGenerate = React.useCallback(() => {
      if (entry instanceof WorkoutTemplateEntry) {
        setProcessing(true);
        entry
          .generate()
          .then((res) => setGeneratedWorkout(res))
          .finally(() => setProcessing(false));
      }
    }, [entry]);

    const handleCloseGeneratedWorkoutModal = React.useCallback(() => setGeneratedWorkout(undefined), []);

    const handleClickMedia = React.useCallback((media: Media) => {
      setSelectedMedia(media);
    }, []);

    const handleCloseMedia = React.useCallback(() => setSelectedMedia(undefined), []);

    const handleMediaSave = React.useCallback(
      (media: Media) => {
        runInAction(() => {
          if (entry.images.some((v) => v.id === media.id)) {
            const index = entry.images.findIndex((v) => v.id === media.id);
            if (index !== -1) {
              entry.images[index] = media;
            }
          } else if (entry.backgroundImage?.id === media.id) {
            entry.backgroundImage = media;
          } else if (entry.backgroundVideos.some((v) => v.id === media.id)) {
            const index = entry.backgroundVideos.findIndex((v) => v.id === media.id);
            if (index !== -1) {
              entry.backgroundVideos[index] = media;
            }
          } else if (entry.videos.some((v) => v.id === media.id)) {
            const index = entry.videos.findIndex((v) => v.id === media.id);
            if (index !== -1) {
              entry.videos[index] = media;
            }
          }
        });

        entry.save();
      },
      [entry],
    );

    const handleMultipleVideosMixed = React.useCallback(
      (medias: Media[], rootMedia: Media) => {
        setProcessing(true);
        runInAction(() => {
          if (entry.backgroundVideos.some((v) => v.id === rootMedia.id)) {
            entry.backgroundVideos = medias.sort((a, b) => (a.language === 'de' ? -1 : 0));
          } else if (entry.videos.some((v) => v.id === rootMedia.id)) {
            entry.videos = medias.sort((a, b) => (a.language === 'de' ? -1 : 0));
          }
        });
        entry.save().finally(() => setProcessing(false));
      },
      [entry],
    );

    const handleExport = React.useCallback(() => {
      setProcessing(true);
      HttpBackend.get(`/coach/schedule/event/subscription/admin/exportCsv`, { objectId: entry.id })
        .then((res) => new Blob([res], { type: 'text/plain;charset=iso-8859-1' }))
        .then((blob) => saveAs(blob, `${entry.getName('de')}-customers-${dayjs().format('YYYYMMDDHHmm')}.csv`))
        .finally(() => setProcessing(false));
    }, [entry]);

    return (
      <React.Fragment>
        <Modal
          open={true}
          title={isNew ? 'New Entry' : entry.defaultName}
          onCancel={handleCancel}
          footer={[
            <React.Fragment key="0">
              <Button key="0" onClick={handleExport} disabled={processing}>
                CSV Export Subscribed Users
              </Button>
            </React.Fragment>,
            <Button key="1" onClick={handleCancel} disabled={processing}>
              Close
            </Button>,
            entry.type === 'workoutTemplate' || entry.type === 'workplace' ? (
              <Button key="1" onClick={handleGenerate} disabled={processing}>
                Generate
              </Button>
            ) : null,
            <React.Fragment key="2">
              {entry.state === 'published' ? (
                <Button danger onClick={handleUnpublish} disabled={processing || isNew}>
                  Unpublish
                </Button>
              ) : (
                <Button danger onClick={handleDelete} disabled={processing || isNew}>
                  Delete
                </Button>
              )}
            </React.Fragment>,
            <Button key="3" type="primary" onClick={handleSave} disabled={processing || isDefault}>
              {entry.publishDate ? 'Push Update' : 'Save Draft'}
            </Button>,
            <React.Fragment key="4">
              {entry.state === 'unpublished' || entry.state === 'draft' ? (
                <Button key="4" type="primary" onClick={handlePublish} disabled={processing || isDefault}>
                  Publish Entry
                </Button>
              ) : null}
            </React.Fragment>,
          ]}
          confirmLoading={processing}
          width={window.innerWidth * 0.75}
          style={{ paddingBottom: 64 }}
        >
          {entry.gym && (
            <SingleColRow>
              <Alert type="warning" message={`You are in '${entry.gym?.name}'`} />
            </SingleColRow>
          )}
          <Form layout="vertical">
            <Tabs activeKey={activeTab} onChange={handleChangeTab}>
              <Tabs.TabPane tab="Basic Info" key="basic">
                <TabBasicInfo entry={entry} onEntryChanged={onEntryChanged} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Medias" key="media" disabled={isDefault}>
                <ExploreEntryModalVideoImageFields entry={entry} onClickMedia={handleClickMedia} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Notifications" key="notifications" disabled={!canHaveNotifications}>
                {canHaveNotifications && <TabNotification entry={entry} />}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Content" key="content" disabled={isDefault}>
                <ExploreEntryContentSwitch entry={entry} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="FAQ" key="faq-entries" disabled={isDefault || isNew}>
                <FaqTabContent entry={entry} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Analytics" key="analytics" disabled={isNew}>
                {!isNew && <ExploreEntryModalAnalytics entry={entry} />}
              </Tabs.TabPane>
            </Tabs>
          </Form>
        </Modal>
        {generatedWorkout && (
          <Modal
            open={true}
            onCancel={handleCloseGeneratedWorkoutModal}
            title={`Generated Workout - ${generatedWorkout.durationMinutes}min`}
          >
            <CoachWorkoutView workout={generatedWorkout} />
          </Modal>
        )}
        {selectedMedia && (
          <MediaEditModal
            media={selectedMedia}
            onCancel={handleCloseMedia}
            onSave={handleMediaSave}
            onMultipleVideosMixed={handleMultipleVideosMixed}
          />
        )}
      </React.Fragment>
    );
  },
);
