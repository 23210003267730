/**
 * Created by neo on 29.02.2024
 */
import { computed, observable, onBecomeObserved, runInAction } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';
import { Gym } from '../Gym/Gym';
import {
  CampaignCalendarQueryRequest,
  CampaignCalendarTemplateItem,
  CampaignCalendarTemplateItemJson,
} from './CampaignCalendarTemplateItem';
import { ExploreCategoryQueryRequest } from '../Explore/ExploreCategory';

export type CampaignCalendarTemplateFolderJson = CampaignCalendarTemplateItemJson & {};

export class CampaignCalendarTemplateFolder extends CampaignCalendarTemplateItem {
  constructor(json?: Partial<CampaignCalendarTemplateFolderJson>) {
    super(Object.assign({}, json, { type: 'folder' }));
    if (json) {
    }

    onBecomeObserved(this, 'gym', () => this.fetchGym());
  }

  fetchGym() {
    if (!this.gym && this.gymId) {
      Gym.get(this.gymId).then((gym) => runInAction(() => (this.gym = gym)));
    }
  }

  toJS(newId?: boolean): CampaignCalendarTemplateFolderJson {
    return {
      ...super.toJS(newId),
    };
  }

  copy(): CampaignCalendarTemplateFolder {
    const copy = new CampaignCalendarTemplateFolder(this.toJS(true));
    copy.name = copy.name.map(({ lang, value }) => new LocalizedValue({ lang, value: `${value} (Copy)` }));
    return copy;
  }

  static find(request?: Partial<CampaignCalendarQueryRequest>): Promise<CampaignCalendarTemplateFolder[]> {
    return HttpBackend.get(`/gym/campaign/calendar/template/admin`, Object.assign({}, request, { type: 'folder' })).then((res) =>
      res.map((c) => new CampaignCalendarTemplateFolder(c)),
    );
  }

  static count(request?: Partial<CampaignCalendarQueryRequest>): Promise<number> {
    return HttpBackend.get(`/gym/campaign/calendar/template/admin/count`, Object.assign({}, request, { type: 'folder' })).then(
      (res) => res ?? 0,
    );
  }

  static findOne(campaignId: string): Promise<CampaignCalendarTemplateFolder> {
    return HttpBackend.get(`/gym/campaign/calendar/template/admin/${campaignId}`).then(
      (res) => new CampaignCalendarTemplateFolder(res),
    );
  }

  static findAll(ids: string[]): Promise<CampaignCalendarTemplateFolder[]> {
    return HttpBackend.post(`/gym/campaign/calendar/template/admin/findAll`, ids).then((result) =>
      (result ?? []).map((res) => new CampaignCalendarTemplateFolder(res)),
    );
  }
}
