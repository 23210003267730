/**
 * Created by neo on 21.03.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { Button, Form, Input, Modal, Select, Space } from 'antd';
import { Col, Container, Row } from 'reactstrap';
import { Route, Routes, useNavigate } from 'react-router';
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { ExploreContentEditModal } from '../ExploreContentEditModal';
import { Gym } from '../../../../Model/Gym/Gym';
import { ExploreEntry } from '../../../../Model/Explore/ExploreEntry';
import { ExploreContent } from '../../../../Model/Explore/ExploreContent';
import { ExploreEntryBuilder } from '../../../../Model/Explore/ExploreEntryBuilder';
import { PageResult } from '../../../../Model/PageResult';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { Pager } from '../../../../Components/Pager';
import styled from '@emotion/styled';
import { ExploreEntryListEntryCard } from './ExploreEntryListEntryCard';
import { TranslateExploreEntriesComponent } from './TranslateExploreEntriesComponent';
import { useDebouncedEffect } from '../../../../Utils/useDebouncedEffect';
import { TagAllContentComponent } from './TagAllContentComponent';
import { EnhanceTagsAndDescriptionComponent } from './EnhanceTagsAndDescriptionComponent';
import { AddHourTagsContentComponent } from './AddHourTagsContentComponent';
import { FilterTagAllContentComponent } from './FilterTagAllContentComponent';
import { FilterTagAllNonEmptyContentComponent } from './FilterTagAllNonEmptyContentComponent';

const DEFAULT_SIZE = 52;

const EntryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const EntryWrapper = styled.div`
  flex: 1 0 25%;
`;

export type ExploreEntriesListScreenProps = {};

export const ExploreEntriesListScreen: React.FC<ExploreEntriesListScreenProps> = observer((props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const gymId = searchParams.get('gymId') ?? undefined;
  const type = searchParams.get('type') ?? undefined;

  const [contents, setContents] = useState<PageResult<ExploreEntry>>(new PageResult());
  const [selectedGym, setSelectedGym] = useState<Gym | undefined>();
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);

  const loadContent = React.useRef(
    (gymId?: string, query?: string, type?: string, page: number = 0, size: number = DEFAULT_SIZE) =>
      PageResult.execute(
        ExploreEntryBuilder.find({
          gymId,
          page,
          query,
          ignoreGymId: !gymId,
          matchGymId: !!gymId,
          type: type ? [type] : [],
          size,
          sort: 'createdAt,DESC',
        }),
        ExploreEntryBuilder.count({
          gymId,
          query,
          type: type ? [type] : [],
          ignoreGymId: !gymId,
          matchGymId: !!gymId,
        }),
        page,
        size,
      ),
  ).current;

  useEffect(() => {
    setSelectedGym(undefined);
    if (gymId) {
      Gym.get(gymId).then((result) => setSelectedGym(result));
    }
  }, [gymId]);

  useDebouncedEffect(() => {
    setContents(new PageResult());
    loadContent(gymId, query, type, page, DEFAULT_SIZE).then((res) => setContents(res));
  }, [page, query, type, gymId]);

  const fetchGym = React.useCallback(
    (query: string) =>
      Gym.find({
        query,
        size: 100,
      }),
    [],
  );

  const handleChange = React.useCallback(
    (gym?: Gym | null) => {
      setSelectedGym(gym ?? undefined);
      setSearchParams((prev) => new URLSearchParams({ ...Object.fromEntries(prev), gymId: gym?.id ?? '' }));
    },
    [setSearchParams],
  );

  const getOptionLabel = React.useCallback((option: Gym) => option.name, []);

  const getOptionValue = React.useCallback((option: Gym) => option, []);

  const handleContentSaved = React.useCallback(
    (entry: ExploreContent) => {
      if (entry instanceof ExploreEntry) {
        loadContent(gymId, query, type, page, DEFAULT_SIZE).then((res) => setContents(res));
      }
    },
    [gymId, loadContent, page, query, type],
  );

  const handleContentDeleted = React.useCallback(
    (entry: ExploreContent) => {
      loadContent(gymId, query, type, page, DEFAULT_SIZE).then((res) => setContents(res));
    },
    [gymId, loadContent, page, query, type],
  );

  const handlePage = React.useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeQuery = React.useCallback(({ target: { value } }) => {
    setQuery(value);
    setPage(0);
  }, []);

  const handleChangeType = React.useCallback(
    (value) => {
      setPage(0);
      setSearchParams(
        (prev) => new URLSearchParams({ ...Object.fromEntries(prev), type: value === 'all' ? '' : value }),
      );
    },
    [setSearchParams],
  );

  return (
    <React.Fragment>
      <Form layout="vertical">
        <Container>
          <Row>
            <Col xs={12}>
              <Row>
                <Col>
                  <h1>Explore Entries</h1>
                </Col>
                <Col xs="auto">
                  <Space>
                    <TranslateExploreEntriesComponent />
                    <TagAllContentComponent />
                    <FilterTagAllContentComponent />
                    <FilterTagAllNonEmptyContentComponent />
                    <AddHourTagsContentComponent />
                    <EnhanceTagsAndDescriptionComponent />
                  </Space>
                </Col>
              </Row>
              <h6>{selectedGym?.name ?? 'Global'}</h6>
            </Col>
            <Col>
              <Form.Item label="Search...">
                <Input value={query} onChange={handleChangeQuery} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Select a gym to view gym specific explore tabs">
                <AsyncSelect
                  value={selectedGym}
                  cacheOptions
                  defaultOptions
                  isClearable={true}
                  loadOptions={fetchGym}
                  getOptionLabel={getOptionLabel}
                  getOptionValue={getOptionValue as any}
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Type">
                <Select value={type || 'all'} onChange={handleChangeType}>
                  <Select.Option value="all">All</Select.Option>
                  <Select.Option value="article">Article</Select.Option>
                  <Select.Option value="activityWorkout">Activity Workout</Select.Option>
                  <Select.Option value="workplace">Workplace Workout</Select.Option>
                  <Select.Option value="workoutTemplate">Workout Template</Select.Option>
                  <Select.Option value="meditation">Meditation</Select.Option>
                  <Select.Option value="breathing">Breathing</Select.Option>
                  <Select.Option value="yoga">Yoga</Select.Option>
                  <Select.Option value="onlineMeeting">Online Meeting</Select.Option>
                  <Select.Option value="offlineMeeting">Offline Meeting</Select.Option>
                  <Select.Option value="video">Video</Select.Option>
                  <Select.Option value="webinar">Webinar</Select.Option>
                  <Select.Option value="link">Link</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <SingleColRow>
            <Pager page={contents} onPage={handlePage} />
            <EntryContainer>
              {contents.content.map((entry) => (
                <EntryWrapper key={entry.id}>
                  <ExploreEntryListEntryCard entry={entry} gymId={gymId} />
                </EntryWrapper>
              ))}
            </EntryContainer>
            <Pager page={contents} onPage={handlePage} />
          </SingleColRow>
        </Container>
      </Form>
      <Routes>
        <Route
          path={`:contentId/edit`}
          element={<ExploreContentEditModal onSaved={handleContentSaved} onDeleted={handleContentDeleted} />}
        />
      </Routes>
    </React.Fragment>
  );
});
