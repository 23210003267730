/**
 * Created by neo on 04.03.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Media } from '../../../../Model/Media/Media';
import { CampaignCalendarTemplateEntry } from '../../../../Model/CampaignCalendar/CampaignCalendarTemplateEntry';
import { CampaignCalendarEntryAttachment } from '../../../../Model/CampaignCalendar/CampaignCalendarEntryAttachment';
import { Button, Checkbox, Form, message, Select, Space } from 'antd';
import { Col, Row } from 'reactstrap';
import { TranslationInputArray } from '../../../../Components/Translation/TranslationInputArray';
import { runInAction } from 'mobx';
import { MediaEditModal } from '../../../MetaData/Media/MediaEditModal/MediaEditModal';

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export type CampaignCalendarEntryModalFilesTabTableRowProps = {
  campaign: CampaignCalendarTemplateEntry;
  attachment: CampaignCalendarEntryAttachment;
  index: number;
};

export const CampaignCalendarEntryModalFilesTabTableRow: React.FC<CampaignCalendarEntryModalFilesTabTableRowProps> =
  observer(({ campaign, attachment, index }) => {
    const [open, setOpen] = useState(false);
    const [savingFile, setSavingFile] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState<Media | undefined>();

    const isSetAsEntryImage = !!campaign.images.find((img) => img.id === attachment.file.id) ?? false

    const handleClick = (e) => {
      e.preventDefault();
      setOpen((p) => !p);
    };

    const handleDelete = () => {
      campaign.attachments.splice(index, 1);
    };

    const handleView = () => {
      window.open(attachment.file.url, '_blank');
    };

    const handleChangeLang = (value) => {
      runInAction(() => (attachment.file.language = value ? value : undefined));
      setSavingFile(true);
      Promise.all([attachment.file.save(), campaign.save()])
        .then(() => {
          message.success('File language changed');
        })
        .catch(() => message.error('Failed to save file'))
        .finally(() => setSavingFile(false));
    };

    const handleCheckbox = React.useCallback(
      ({ target: { checked } }) => {
        runInAction(() => (attachment.template = checked));
        setSavingFile(true);
        campaign
          .save()
          .then(() => {
            message.success('Attachment changed');
          })
          .catch(() => message.error('Failed to save attachment'))
          .finally(() => setSavingFile(false));
      },
      [attachment, campaign],
    );

    const handleEditMedia = () => setSelectedMedia(attachment.file);

    const handleCloseMediaModal = () => setSelectedMedia(undefined);

    const handleSaveAndCloseMediaModal = () => {
      setSavingFile(true);
      campaign
        .save()
        .then(() => {
          message.success('Image logo placement set');
        })
        .catch(() => message.error('Failed to save image'))
        .finally(() => setSavingFile(false));
      setSelectedMedia(undefined);
    };

    const handleSetAsEntryImage = React.useCallback(() => {
        if (isSetAsEntryImage) {
            runInAction(() => {
                campaign.images = campaign.images.filter((img) => img.id !== attachment.file.id);
            });
        } else {
            runInAction(() => {
                campaign.images.push(attachment.file);
            });
            campaign
                .save()
                .then(() => {
                    message.success('Entry image changed');
                })
                .catch(() => message.error('Failed to save entry image'));
        }

    },[campaign, attachment]);

    return (
      <React.Fragment>
        <tr>
          <td>{attachment.id}</td>
          <td>
            <a href="" onClick={handleClick}>
              {attachment.file.name}
            </a>
          </td>
            <td>
                <Checkbox
                    checked={isSetAsEntryImage}
                    onChange={handleSetAsEntryImage}
                />
            </td>
          <td>
            <Select
              value={attachment.file.language}
              onChange={handleChangeLang}
              disabled={savingFile}
              size="small"
              style={{ minWidth: 120 }}
            >
              <Select.Option>None / All</Select.Option>
              <Select.Option value="en">English</Select.Option>
              <Select.Option value="de">German</Select.Option>
              <Select.Option value="fr">French</Select.Option>
              <Select.Option value="it">Italian</Select.Option>
              <Select.Option value="es">Spanish</Select.Option>
              <Select.Option value="pt">Portuguese</Select.Option>
              <Select.Option value="fi">Finnish</Select.Option>
            </Select>
          </td>
          <td>
            {attachment.canBeTemplate && (
              <Form.Item>
                <Checkbox onChange={handleCheckbox} checked={attachment.template}>
                  Allow gym logo
                </Checkbox>
              </Form.Item>
            )}
          </td>
          <td>{attachment.defaultName}</td>
          <td>{formatBytes(attachment.file.size)}</td>
          <td>
            <Space>
              <Button size="small" danger onClick={handleDelete}>
                Delete
              </Button>
              <Button size="small" onClick={handleView}>
                View
              </Button>
              {attachment.template && (
                <Button size="small" onClick={handleEditMedia}>
                  Edit Logo Area
                </Button>
              )}
            </Space>
          </td>
        </tr>
        {open && (
          <tr>
            <td colSpan={4}>
              <Row>
                <Col md={6}>
                  <Form.Item label="Name">
                    <TranslationInputArray entity={attachment} field="name" />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item label="Description">
                    <TranslationInputArray entity={attachment} type="textarea" field="description" />
                  </Form.Item>
                </Col>
              </Row>
            </td>
          </tr>
        )}
        {selectedMedia && (
          <MediaEditModal media={selectedMedia} onOk={handleSaveAndCloseMediaModal} onCancel={handleCloseMediaModal} />
        )}
      </React.Fragment>
    );
  });
