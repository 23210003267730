/**
 *
 * Created by neo on 15.01.17.
 */

import { observable, action, onBecomeObserved } from 'mobx';
import { Athlete } from '../Athlete/Athlete';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Gym } from '../Gym/Gym';
import { v4 as UUID } from 'uuid';
import { CustomerPointsLedger } from '../Engagement/Ledger/CustomerPointsLedger';

export class Customer {
  @observable
  id: string = '';
  @observable
  gymId: string = '';
  @observable
  athlete: Athlete;
  @observable
  status: string = '';
  @observable
  rewardsPointsCollectionEnabled = false;
  @observable
  gym?: Gym = undefined;
  @observable
  pointsLedger?: CustomerPointsLedger = undefined;

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.gymId = json.gymId;
      this.status = json.status;
      this.athlete = new Athlete(json.athlete);
      this.rewardsPointsCollectionEnabled = json.rewardsPointsCollectionEnabled ?? false;
    }

    if (!this.id || !(this.id.length > 0)) {
      this.id = UUID();
    }

    onBecomeObserved(this, 'gym', this.fetchGym);
    onBecomeObserved(this, 'pointsLedger', this.fetchPointsLedger);
  }

  toJS(): any {
    return {
      id: this.id,
      gymId: this.gymId,
      athleteId: this.athlete.id,
      rewardsPointsCollectionEnabled: this.rewardsPointsCollectionEnabled,
    };
  }

  fetchGym = async () => {
    if (this.gymId && !this.gym) {
      this.gym = await Gym.get(this.gymId);
    }
    return this.gym;
  };

  fetchPointsLedger = async () => {
    if (!this.pointsLedger) {
      this.pointsLedger = await CustomerPointsLedger.findByCustomerId(this.id);
    }
    return this.pointsLedger;
  };

  @action
  save() {
    return Promise.all([this.athlete.save(), HttpBackend.post(`/gym/customer/admin`, this.toJS())]).then(() => this);
  }

  @action
  remove() {
    return HttpBackend.delete(`/gym/customer/admin/${this.id}`);
  }

  static async get(id: string): Promise<Customer | undefined> {
    const res = await HttpBackend.get(`/gym/customer/admin/${id}`);
    if (res) {
      return new Customer(res);
    }
    return undefined;
  }

  static find(data: any = { start: 0, sort: 'athlete.firstname,ASC' }): Promise<Customer[]> {
    return HttpBackend.get(`/gym/customer/admin`, data).then((res) => (res ?? []).map((c) => new Customer(c)));
  }

  static count(data: any = { start: 0, sort: 'athlete.firstname,ASC' }): Promise<number> {
    return HttpBackend.get(`/gym/customer/admin/count`, data).then((res) => res ?? 0);
  }
}
