/**
 * Created by neo on 04.03.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { DatePicker, Form, message, Modal, Select, Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import { CampaignCalendarTemplateEntry } from '../../../../Model/CampaignCalendar/CampaignCalendarTemplateEntry';
import { useNavigate } from 'react-router';
import { CampaignCalendarEntryModalBasicTab } from './CampaignCalendarEntryModalBasicTab';
import { CampaignCalendarEntryModalFilesTab } from './CampaignCalendarEntryModalFilesTab';

export type CampaignCalendarEntryModalProps = {
  campaign: CampaignCalendarTemplateEntry;
  isNew?: boolean;
  onSave?: (entry: CampaignCalendarTemplateEntry) => void;
  onDeleted?: (entry: CampaignCalendarTemplateEntry) => void;
};

export const CampaignCalendarEntryModal: React.FC<CampaignCalendarEntryModalProps> = observer(
  ({ onSave, onDeleted, campaign, isNew }) => {
    const history = useNavigate();

    const [processing, setProcessing] = useState(false);
    const [activeTab, setActiveTab] = useState('basic');

    const handleSave = () => {
      if (!processing) {
        setProcessing(true);
        campaign
          .save()
          .finally(() => setProcessing(false))
          .then(() => {
            message.success('Campaign saved');
          })
          .then(() => onSave?.(campaign))
          .then(() => history(-1));
      }
    };

    const handleClose = () => {
      history(-1);
    };

    const handleChangeTab = React.useCallback((tab: string) => setActiveTab(tab), []);

    return (
      <Modal
        title={isNew ? 'New Campaign Template' : campaign.defaultName}
        open={true}
        width={1920}
        onOk={handleSave}
        onCancel={handleClose}
        confirmLoading={processing}
      >
        <Form layout="vertical">
          <Tabs defaultActiveKey="1" onChange={handleChangeTab}>
            <Tabs.TabPane tab="Basic" key="basic">
              <CampaignCalendarEntryModalBasicTab campaign={campaign} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Files" key="files">
              <CampaignCalendarEntryModalFilesTab campaign={campaign} />
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </Modal>
    );
  },
);
