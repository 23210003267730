/**
 * Created by neo on 11.01.21.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Alert, Button, Checkbox, DatePicker, Form, Input, InputNumber, Modal, Select } from 'antd';
import { useNavigate } from 'react-router';
import { reaction, runInAction } from 'mobx';
import AsyncSelect from 'react-select/async';
import { Col, Row } from 'reactstrap';
import dayjs, { Dayjs } from 'dayjs';
import { CampaignCalendarTemplateFolder } from '../../../../Model/CampaignCalendar/CampaignCalendarTemplateFolder';
import { Media } from '../../../../Model/Media/Media';
import { Gym } from '../../../../Model/Gym/Gym';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { TranslationInputArray } from '../../../../Components/Translation/TranslationInputArray';
import { ExploreEntryModalMediaField } from '../../../Coach/Explore/ExploreEntryModalMediaField';
import { MediaEditModal } from '../../../MetaData/Media/MediaEditModal/MediaEditModal';

export type CampaignCalendarTemplateFolderEditModalProps = {
  category: CampaignCalendarTemplateFolder;
  onSaved?: (category: CampaignCalendarTemplateFolder) => void;
  isNew?: boolean;
};

export const CampaignCalendarTemplateFolderEditModal: React.FC<CampaignCalendarTemplateFolderEditModalProps> = observer(
  ({ category, onSaved, isNew }) => {
    const history = useNavigate();
    const [processing, setProcessing] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState<Media | undefined>();
    const [parent, setParent] = useState<CampaignCalendarTemplateFolder | undefined>(undefined);
    const [gym, setGym] = useState<Gym | undefined>();

    console.log('category', category);

    useEffect(() => {
      if (category.parentIds.length > 0) {
        CampaignCalendarTemplateFolder.findAll(category.parentIds).then((fetched) => {
          setParent(fetched[0]);
        });
      } else {
        setParent(undefined);
      }
    }, [category]);

    useEffect(
      () =>
        reaction(
          () => category.gymId,
          (gymId) => {
            setGym(undefined);
            if (gymId) {
              Gym.get(gymId).then((res) => setGym(res));
            }
          },
          { fireImmediately: true },
        ),
      [category],
    );

    const handleSave = React.useCallback(() => {
      setProcessing(true);
      onSaved && onSaved(category);
      category
        .save()
        .finally(() => setProcessing(false))
        .then(() => history(-1));
    }, [category, onSaved, history]);

    const handleCancel = React.useCallback(() => history(-1), [history]);

    const handleChangeParent = React.useCallback(
      (selectedParent: CampaignCalendarTemplateFolder | null) => {
        runInAction(() => {
          category.parentIds = selectedParent ? [selectedParent.id] : [];
        });
        setParent(selectedParent ?? undefined);
      },
      [category],
    );

    const fetchParent = React.useCallback(
      (query: string) =>
        CampaignCalendarTemplateFolder.find({ query, size: 50 }).then((res) =>
          res.filter((n) => !category.parentIds.some((c) => c === n.id)),
        ),
      [category],
    );

    const getOptionLabel = React.useCallback(
      (option: CampaignCalendarTemplateFolder) => `${option.defaultName} (${option.id})`,
      [],
    );

    const getOptionValue = React.useCallback((option: CampaignCalendarTemplateFolder) => option, []);

    const handleChangePublishDate = React.useCallback(
      (date) => runInAction(() => (category.publishDate = date?.toDate())),
      [category],
    );

    const handleChangeUnpublishDate = React.useCallback(
      (date) => runInAction(() => (category.unpublishDate = date?.toDate())),
      [category],
    );

    const handleImageUploaded = React.useCallback(
      (media: Media) => {
        runInAction(() => category.images.push(media));
      },
      [category],
    );

    const handleRemoveImage = React.useCallback(
      (media: Media) => {
        runInAction(() => (category.images = category.images.filter((v) => v.id !== media.id)));
      },
      [category],
    );

    const handleUnpublish = React.useCallback(() => {
      runInAction(
        () =>
          (category.unpublishDate =
            category.unpublishDate && dayjs(category.unpublishDate).isBefore(dayjs())
              ? category.unpublishDate
              : new Date()),
      );

      setProcessing(true);
      onSaved && onSaved(category);
      category
        .save()
        .finally(() => setProcessing(false))
        .then(() => history(-1));
    }, [category, history, onSaved]);

    const handlePublish = React.useCallback(() => {
      runInAction(() => {
        category.publishDate =
          category.publishDate && dayjs(category.publishDate).isBefore(dayjs()) ? category.publishDate : new Date();
        category.unpublishDate =
          !category.unpublishDate || dayjs(category.unpublishDate).isAfter(dayjs())
            ? category.unpublishDate
            : undefined;
      });

      setProcessing(true);
      category
        .save()
        .finally(() => setProcessing(false))
        .then(() => history(-1));
      onSaved && onSaved(category);
    }, [category, history, onSaved]);

    const handleClickMedia = React.useCallback((media: Media) => {
      setSelectedMedia(media);
    }, []);

    const handleCloseMedia = React.useCallback(() => setSelectedMedia(undefined), []);

    const handleMediaSave = React.useCallback(
      (media: Media) => {
        runInAction(() => {
          if (category.images.some((v) => v.id === media.id)) {
            const index = category.images.findIndex((v) => v.id === media.id);
            if (index !== -1) {
              category.images[index] = media;
            }
          }
        });

        category.save();
      },
      [category],
    );

    const fetchGym = React.useCallback((query: string) => Gym.find({ query }), []);

    const optionLabel = React.useCallback((obj) => obj.name, []);

    const optionValue = React.useCallback((obj) => obj, []);

    const handleChangeObject = React.useCallback(
      (value?: Gym) => {
        runInAction(() => (category.gymId = value?.id));
      },
      [category],
    );

    return (
      <React.Fragment>
        <Modal
          open={true}
          title={isNew ? 'New Category' : category.defaultName}
          onOk={handleSave}
          onCancel={handleCancel}
          confirmLoading={processing}
          footer={[
            <Button key="1" onClick={handleCancel} disabled={processing}>
              Close
            </Button>,
            <React.Fragment key="2">
              {category.published ? (
                <Button danger onClick={handleUnpublish} disabled={processing || isNew}>
                  Unpublish
                </Button>
              ) : null}
            </React.Fragment>,
            <Button key="3" type="primary" onClick={handleSave} disabled={processing || category.type === 'default'}>
              {category.publishDate ? 'Push Update' : 'Save Draft'}
            </Button>,
            <React.Fragment key="4">
              {!category.published ? (
                <Button
                  key="4"
                  type="primary"
                  onClick={handlePublish}
                  disabled={processing || category.type === 'default'}
                >
                  Publish Entry
                </Button>
              ) : null}
            </React.Fragment>,
          ]}
          width={1280}
        >
          <Form layout="vertical">
            {category.gym && (
              <SingleColRow>
                <Alert type="warning" message={`You are in '${category.gym?.name}'`} />
              </SingleColRow>
            )}
            <Row>
              <Col xs={6}>
                <Form.Item label="Parents">
                  <AsyncSelect
                    defaultOptions
                    isMulti={false}
                    isClearable={true}
                    loadOptions={fetchParent}
                    value={parent}
                    hideSelectedOptions={false}
                    getOptionLabel={getOptionLabel}
                    getOptionValue={getOptionValue as any}
                    onChange={handleChangeParent as any}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item label="Valid From">
                  <DatePicker
                    value={category.publishDate ? dayjs(category.publishDate) : undefined}
                    onChange={handleChangePublishDate}
                    allowClear={true}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="Valid Until">
                  <DatePicker
                    value={category.unpublishDate ? dayjs(category.unpublishDate) : undefined}
                    onChange={handleChangeUnpublishDate}
                    allowClear={true}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Item
                  label="Gym / Company"
                  extra="If you change it all sub categories and entries below it will also be assigned to the new gym / company!!"
                >
                  <AsyncSelect
                    cacheOptions
                    value={gym}
                    isClearable={true}
                    loadOptions={fetchGym as any}
                    getOptionLabel={optionLabel}
                    getOptionValue={optionValue as any}
                    onChange={handleChangeObject as any}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TranslationInputArray entity={category} field="name" label="Name" />
              </Col>
              <Col md={6}>
                <TranslationInputArray entity={category} field="description" type="textarea" label="Description" />
              </Col>
            </Row>
            <SingleColRow>
              <Form.Item label="Images">
                <div style={{ display: 'flex' }}>
                  {category.images.concat([undefined] as any).map((media) => (
                    <div key={media?.id} style={{ marginLeft: 8 }}>
                      <ExploreEntryModalMediaField
                        onRemove={handleRemoveImage}
                        onUploaded={handleImageUploaded}
                        onClick={handleClickMedia}
                        media={media}
                      />
                    </div>
                  ))}
                </div>
              </Form.Item>
            </SingleColRow>
          </Form>
        </Modal>
        {selectedMedia && <MediaEditModal media={selectedMedia} onCancel={handleCloseMedia} onSave={handleMediaSave} />}
      </React.Fragment>
    );
  },
);
