import { observable, computed, toJS, onBecomeObserved } from 'mobx';
import { Media } from '../Media/Media';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { EquipmentTypeRequestParams } from './EquipmentTypeRequestParams';
import { LocalizedArrayEntity } from '../LocalizedArrayEntity';

export class EquipmentType extends LocalizedArrayEntity {
  @observable
  newId: string = '';
  @observable
  medias: Array<Media> = [];
  @observable
  includes: Array<string> = [];
  @observable
  flat: Array<EquipmentType> = [];

  constructor(json?: any) {
    super(json);
    if (json) {
      this.medias = (json.medias || []).map((m) => new Media(m));
      this.includes = json.includes || [];
    }
  }

  toJS(): any {
    return Object.assign(super.toJS(), {
      id: this.id ?? '',
      newId: this.newId,
      medias: this.medias.map((m) => m.toJS()),
      includes: toJS(this.includes),
    });
  }

  async resolveAll(): Promise<Array<EquipmentType>> {
    return (
      (await Promise.all(this.includes.map((r) => this.resolveType(r))))
        .flatMap((r) => r)
        // .filter((r: EquipmentType, pos: number, self: Array<EquipmentType>) => self.indexOf((r0: EquipmentType) => r0.id === r.id) === pos)
        .concat([this])
    );
  }

  async resolveType(type: string): Promise<Array<EquipmentType>> {
    const equipmentType = await EquipmentType.get(type);
    if (equipmentType) {
      const types = [equipmentType];
      const includes =
        equipmentType.includes.length > 0
          ? (await Promise.all(equipmentType.includes.map((r) => this.resolveType(r))))
              .filter((e) => !!e)
              .map((e) => e!)
          : [];
      return types.concat(includes.flatMap((r) => r));
    }
    return [];
  }

  @computed
  get isNew(): boolean {
    return !this.id;
  }

  delete() {
    return HttpBackend.delete(`/exercise/equipment/type/${this.id}`);
  }

  async save() {
    return HttpBackend.post('/exercise/equipment/type', this.toJS())
      .then((result) => (this.id = result.id))
      .then(() => this);
  }

  static list(params: any = {}): Promise<EquipmentType[]> {
    return HttpBackend.get('/exercise/equipment/type/list', params).then((res) => (res || []).map((e) => new EquipmentType(e)));
  }

  static find(params: any = {}): Promise<EquipmentType[]> {
    return HttpBackend.get('/exercise/equipment/type', params).then((res) => res.map((e) => new EquipmentType(e)));
  }

  static count(params: any = {}): Promise<number> {
    return HttpBackend.get('/exercise/equipment/type/count', params);
  }

  static async get(id: string): Promise<EquipmentType | undefined> {
    const res = await HttpBackend.get(`/exercise/equipment/type/${id}`);
    if (res) {
      return new EquipmentType(res);
    }
    return undefined;
  }

  static async findCoachSubset(params?: EquipmentTypeRequestParams): Promise<string[]> {
    const data = await HttpBackend.get('/exercise/v2/equipmentTypes?sourceId=flinq', params);
    if (data) {
      return data;
    }
    return [];
  }
}
