/**
 * Created by neo on 04.03.2024
 */
import * as React from 'react';
import { Observer, observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { PageResult } from '../../../Model/PageResult';
import { Col, Container, Row } from 'reactstrap';
import { Button, message, Popconfirm, Space, Table, Tag } from 'antd';
import { Route, Routes, useNavigate } from 'react-router';
import { CampaignCalendarTemplateItemBuilder } from '../../../Model/CampaignCalendar/CampaignCalendarTemplateItemBuilder';
import { CampaignCalendarTemplateItem } from '../../../Model/CampaignCalendar/CampaignCalendarTemplateItem';
import { CampaignCalendarTemplateEntry } from '../../../Model/CampaignCalendar/CampaignCalendarTemplateEntry';
import dayjs from 'dayjs';
import { CampaignCalendarTemplateFolder } from '../../../Model/CampaignCalendar/CampaignCalendarTemplateFolder';
import { CampaignCalendarItemEditModal } from './CampaignCalendarItemEditModal/CampaignCalendarItemEditModal';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Link } from 'react-router-dom';

type RowProps = React.HTMLAttributes<HTMLTableRowElement> & {
  'data-row-key': string;
};

const SortableRow: React.FC<Readonly<RowProps>> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

type TreeNode = {
  id: number;
  item: CampaignCalendarTemplateItem;
  children: TreeNode[];
};

function buildTree(data: CampaignCalendarTemplateItem[]): TreeNode[] {
  // 1. Create a lookup map to convert each FlatNode into a TreeNode with children = []
  const lookup: Record<number, TreeNode> = {};
  data.forEach((item) => {
    lookup[item.id] = Object.assign(
      { id: item.id, item },
      item instanceof CampaignCalendarTemplateFolder ? { children: [] } : {},
    );
  });

  // 2. This will hold the final list of root nodes
  const result: TreeNode[] = [];

  // 3. Populate the hierarchy
  data
    .sort((a, b) => a.parentIds.length - b.parentIds.length)
    .sort((a, b) => (a.type === 'folder' ? -1 : 0))
    .forEach((item) => {
      const currentNode = lookup[item.id];
      // Check if there is a parent
      if (Array.isArray(item.parentIds) && item.parentIds.length > 0) {
        const parentId = item.parentIds[0]; // only consider the first parent ID
        const parentNode = lookup[parentId];
        if (parentNode && parentNode.item instanceof CampaignCalendarTemplateFolder) {
          // Push current node into parent's children array
          parentNode.children.push(currentNode);
        } else {
          // If the parent doesn't exist in the map for some reason, treat it as a root
          result.push(currentNode);
        }
      } else {
        result.push(currentNode);
      }
    });

  return result;
}

export type CampaignCalendarIndexScreenProps = {};

export const CampaignCalendarIndexScreen: React.FC<CampaignCalendarIndexScreenProps> = observer((props) => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [result, setResult] = useState(new PageResult<CampaignCalendarTemplateItem>());

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    }),
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    // 1) If there's no drop target, do nothing
    if (!over) return;
    // 2) If item was dropped on itself, do nothing
    if (active.id === over.id) return;
    // 3) Get a **copy** of all items so we can mutate
    const newData = [...result.content];

    // 4) Find the dragged item
    const draggedItem = newData.find((x) => x.id === active.id);
    if (!draggedItem) return;

    // 5) Find the drop target
    const overItem = newData.find((x) => x.id === over.id);
    if (!overItem) return;

    // 6) If the drop target is a folder, update the dragged item’s parentIds
    if (overItem instanceof CampaignCalendarTemplateFolder) {
      // → Set the folder’s ID as the parent
      draggedItem.parentIds = [overItem.id];
      overItem.parentIds = overItem.parentIds.filter((x) => x !== draggedItem.id);
      Promise.all([draggedItem.save(), overItem.save()]);
    } else {
      // Otherwise do nothing, or if you have a fallback (like "no parent"), do it here
      return;
    }

    // 7) Update the state so our table re-renders
    setResult((prev) => new PageResult({ ...prev, content: newData }) as any);

    // The table is re-rendered because buildTree(result.content) is called
    // in your useMemo. This will show the newly nested item/folder.
  };

  const onDragOver = ({ active, over }: DragEndEvent) => {
    console.log('drag over', active, over);
    if (active.id !== over?.id) {
      // setDataSource((prev) => {
      //   const activeIndex = prev.findIndex((i) => i.key === active.id);
      //   const overIndex = prev.findIndex((i) => i.key === over?.id);
      //   return arrayMove(prev, activeIndex, overIndex);
      // });
    }
  };

  const handleRefresh = React.useCallback(() => {
    PageResult.execute(
      CampaignCalendarTemplateItemBuilder.find({ size: 500, page }),
      CampaignCalendarTemplateItemBuilder.count(),
      page,
      500,
    ).then((res) => setResult(res));
  }, [page]);

  const columns: any = useMemo(
    () => [
      {
        title: 'ID',
        key: 'id',
        render: (text: any, record: TreeNode) => (
          <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block' }}>
            {record.item instanceof CampaignCalendarTemplateFolder ? <b>{record.item.id}</b> : record.item.id}
          </span>
        ),
      },
      {
        title: 'Name',
        key: 'name',
        render: (text: any, record: TreeNode) =>
          record.item instanceof CampaignCalendarTemplateFolder ? (
            <b>{record.item.defaultName}</b>
          ) : (
            record.item.defaultName
          ),
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        render: (text: any, record: TreeNode) => (
          <Observer>
            {() =>
              record.item.gymId ? (
                <Link to={`/infrastructure/gym/${record.item.gymId}`}>{record.item.gym?.name}</Link>
              ) : (
                <span>Global</span>
              )
            }
          </Observer>
        ),
      },
      {
        title: 'Campaign Date',
        dataIndex: 'campaignDate',
        key: 'campaignDate',
        render: (text: any, record: TreeNode) =>
          record.item instanceof CampaignCalendarTemplateEntry
            ? `${dayjs(record.item.entryDate, 'YYYY-MM-DD').format('DD. MMMM YYYY')}\n${record.item.entryTime}`
            : '-',
      },
      {
        title: 'Publish Date',
        key: 'publishDate',
        render: (text: any, record: TreeNode) =>
          record.item.publishDate ? dayjs(record.item.publishDate).format('DD.MM.YYYY') : '-',
      },
      {
        title: 'Unpublish Date',
        key: 'unpublishDate',
        render: (text: any, record: TreeNode) =>
          record.item.unpublishDate ? dayjs(record.item.unpublishDate).format('DD.MM.YYYY') : '-',
      },
      {
        title: 'Published',
        key: 'published',
        render: (text: any, record: TreeNode) =>
          record.item.published ? <Tag color="success">Published</Tag> : <Tag color="error">Unpublished</Tag>,
      },
      {
        title: 'Files',
        key: 'files',
        render: (text: any, record: TreeNode) =>
          record.item instanceof CampaignCalendarTemplateEntry ? record.item.attachments.length : '-',
      },
      {
        title: '',
        key: 'action',
        render: (text: any, record: TreeNode) => (
          <Space>
            <Popconfirm
              title={'Are you sure?'}
              onConfirm={() =>
                record.item
                  .delete()
                  .then(() => handleRefresh())
                  .then(() =>
                    Promise.all(
                      result.content
                        .filter((c) => c.parentIds.includes(record.item.id))
                        .map((c) => {
                          c.parentIds = c.parentIds.filter((p) => p !== record.item.id);
                          return c.save();
                        }),
                    ),
                  )
                  .then(() =>
                    message.success(
                      record.item instanceof CampaignCalendarTemplateFolder ? 'Folder deleted' : 'Campaign deleted',
                    ),
                  )
                  .catch(() =>
                    message.error(
                      record.item instanceof CampaignCalendarTemplateFolder
                        ? 'Failed to delete folder'
                        : 'Failed to delete campaign',
                    ),
                  )
              }
            >
              <Button danger size="small">
                Delete
              </Button>
            </Popconfirm>
            <Button type="link" onClick={() => navigate(`/customer-success/campaign-calendar/${record.id}`)}>
              Edit
            </Button>
            {record.item instanceof CampaignCalendarTemplateEntry && (
              <Button
                type="link"
                onClick={() =>
                  record.item
                    .copy()
                    .save()
                    .then(() => handleRefresh())
                    .then(() => message.success('Campaign copied'))
                    .catch(() => message.error('Failed to copy campaign'))
                }
              >
                Copy
              </Button>
            )}
            {record.item instanceof CampaignCalendarTemplateFolder && (
              <Button
                type="link"
                onClick={() => navigate(`/customer-success/campaign-calendar/create?parentId=${record.id}&type=folder`)}
              >
                Create Sub Folder
              </Button>
            )}
            {record.item instanceof CampaignCalendarTemplateFolder && (
              <Button
                type="link"
                onClick={() => navigate(`/customer-success/campaign-calendar/create?parentId=${record.id}&type=entry`)}
              >
                Create Entry
              </Button>
            )}
          </Space>
        ),
      },
    ],
    [handleRefresh, navigate, result],
  );
  const dataSource = useMemo(() => buildTree(result.content), [result]);

  useEffect(() => {
    PageResult.execute(
      CampaignCalendarTemplateItemBuilder.find({ size: 500, page }),
      CampaignCalendarTemplateItemBuilder.count(),
      page,
      500,
    ).then((res) => setResult(res));
  }, [page]);

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col>
            <h1>Campaign Calendar Templates</h1>
          </Col>
          <Col xs="auto">
            <Button type="primary" onClick={() => navigate(`/customer-success/campaign-calendar/create?type=folder`)}>
              Create new folder
            </Button>
          </Col>
        </Row>
        <DndContext
          sensors={sensors}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={onDragEnd}
          onDragOver={onDragOver}
        >
          <SortableContext items={dataSource.map((i) => i.id)} strategy={verticalListSortingStrategy}>
            <Table<TreeNode>
              components={{
                body: { row: SortableRow },
              }}
              rowKey="id"
              columns={columns}
              dataSource={dataSource}
            />
          </SortableContext>
        </DndContext>
      </Container>
      <Routes>
        <Route
          path={`:campaignId`}
          element={<CampaignCalendarItemEditModal onSaved={handleRefresh} onDeleted={handleRefresh} />}
        />
      </Routes>
    </React.Fragment>
  );
});
