/**
 * Created by neo on 04.03.2024
 */
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';
import { Media, MediaJson } from '../Media/Media';
import { computed, observable } from 'mobx';
import { v4 as UUID } from 'uuid';
import { LocalizedArrayEntity, LocalizedArrayEntityJson } from '../LocalizedArrayEntity';

export type CampaignCalendarEntryAttachmentJson = LocalizedArrayEntityJson & {
  name: LocalizedValueJson[];
  description: LocalizedValueJson[];
  file: MediaJson;
  template: boolean;
};

export class CampaignCalendarEntryAttachment extends LocalizedArrayEntity {
  @observable
  file = new Media();
  @observable
  template = false;

  constructor(json: Partial<CampaignCalendarEntryAttachmentJson>) {
    super(json);
    this.file = new Media(json.file);
    this.template = json?.template ?? false;
  }

  @computed
  get canBeTemplate(): boolean {
    return (
      this.file?.mediaType.startsWith('image/') ||
      this.file?.mediaType.startsWith('video/') ||
      this.file?.mediaType.startsWith('application/pdf')
    );
  }

  toJS(): CampaignCalendarEntryAttachmentJson {
    return {
      ...super.toJS(),
      file: this.file.toJS(),
      template: this.template,
    };
  }
}
